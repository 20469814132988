import { css } from '@emotion/react';
import theme from '@landing/styles/theme';
import Link from 'next/link';
import { useState } from 'react';

import { AUDIO_PREVIEW_ANNOUNCER } from '../../data';
import { AnnouncerPreviewGrid } from './AnnouncerPreviewGrid';

export default function AnnouncerPreviewArea() {
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <div
      css={css`
        width: 100%;
        overflow: clip;
        background: #edeff4;
        padding: 100px 0;
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          ${theme.mobile} {
            flex-direction: column;
          }
          // gap: 16px;
        `}
      >
        <h1
          css={css`
            margin: 0;
            font-weight: 500;
            font-size: 3rem;
            ${theme.mobile} {
              font-size: 1.8rem;
            }
            text-align: center;
            display: flex;
            align-items: center;
          `}
        >
          <img
            alt="MBC"
            src="/images/logo_mbc.png"
            css={css`
              height: 2.4rem;
              ${theme.mobile} {
                height: 1.4rem;
              }
            `}
          />

          <span
            css={css`
              margin-left: 4px;
              color: #324554;
              font-family: Y_Spotlight;
            `}
          >
            아나운서
          </span>
        </h1>

        <span
          css={css`
            margin-left: 8px;
            background: linear-gradient(90deg, #436bff 0%, #06bad3 100%);
            background-clip: text;
            text-fill-color: transparent;
            font-family: Y_Spotlight;
            font-weight: 500;
            font-size: 3rem;
            ${theme.mobile} {
              font-size: 1.8rem;
            }
          `}
        >
          AI 보이스
        </span>

        {/* <Link
          href={{
            pathname: '/support/inquiry/create',
            query: { type: 'AFFILIATE' },
          }}
          css={css`
            margin: 16px;

            user-select: none;

            background: #3c64ff;
            border-radius: 6px;
            padding: 8px 16px;

            color: #fff;
            font-weight: 300;
            font-size: 20px;
            ${theme.mobile} {
              font-size: 16px;
            }
          `}
        >
          사용 문의
        </Link> */}
      </div>

      <p
        css={css`
          margin-top: 40px;
          text-align: center;
          font-weight: 300;
          font-size: 1.4rem;
          ${theme.laptop} {
            font-size: 1.3rem;
          }
          ${theme.tablet} {
            fontsize: 1rem;
          }
        `}
      >
        아나운서 AI 보이스로 더 또렷하고 몰입감 높은 콘텐츠를 만들어보세요.
        <br />
        콘텐츠 제작에 아나운서 AI보이스가 필요하시면 사용 문의하기를 통해
        연락주세요!
      </p>

      <div
        css={css`
          padding-top: 200px;
          margin-top: 50px;
          ${theme.mobile} {
            padding-top: 250px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            transform: translateX(-${pageIndex * 100}%);
            transition: transform 0.3s ease-in-out;
          `}
        >
          {AUDIO_PREVIEW_ANNOUNCER.map((announcerList) => (
            <AnnouncerPreviewGrid
              key={announcerList[0].imgUrl}
              announcerList={announcerList}
            />
          ))}
        </div>

        {/* 하단 페이지 변경 버튼 */}
        <div
          css={css`
            display: flex;
            justify-content: center;

            margin-top: 80px;

            ${theme.medium} {
              display: none;
            }
          `}
        >
          {Array.from({ length: AUDIO_PREVIEW_ANNOUNCER.length }).map(
            (_, i) => (
              <button
                key={AUDIO_PREVIEW_ANNOUNCER[i][0].imgUrl}
                type="button"
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                `}
                onClick={() => setPageIndex(i)}
              >
                <div
                  css={css`
                    width: 20px;
                    height: 20px;
                    background: ${pageIndex === i ? '#324554' : '#99A2AA'};
                    border-radius: 50%;
                  `}
                />
              </button>
            ),
          )}
        </div>

        {/* 사이드 페이지 변경 버튼 */}
        <div
          css={css`
            position: sticky;
            bottom: 45svh;
            height: 0;
          `}
        >
          <button
            type="button"
            css={css`
              position: absolute;
              left: 20px;

              display: flex;
              justify-content: center;
              align-items: center;

              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #7d8bb3;
              font-size: 30px;
              color: #fff;
              transform: translateY(-50%);

              display: none;
              ${theme.medium} {
                display: ${pageIndex > 0 ? 'block' : 'none'};
              }
            `}
            onClick={() => setPageIndex((prev) => prev - 1)}
          >
            {'<'}
          </button>
          <button
            type="button"
            css={css`
              position: absolute;
              right: 20px;

              display: flex;
              justify-content: center;
              align-items: center;

              width: 40px;
              height: 40px;
              border-radius: 50%;
              background: #7d8bb3;
              font-size: 30px;
              color: #fff;
              transform: translateY(-50%);

              display: none;
              ${theme.medium} {
                display: ${pageIndex < AUDIO_PREVIEW_ANNOUNCER.length - 1
                  ? 'block'
                  : 'none'};
              }
            `}
            onClick={() => setPageIndex((prev) => prev + 1)}
          >
            {'>'}
          </button>
        </div>
      </div>
    </div>
  );
}
